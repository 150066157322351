<template>
  <v-hover v-slot:default="{ hover }">
    <v-card color="grey lighten-4" @click="$router.push(`/articles/${article.id}`)">
      <v-img :aspect-ratio="16 / 9" :src="article.featurePicture"> </v-img>
      <v-card-text class="pt-8" style="position: relative;">
        <h3 class="text-xl-h5 font-weight-light primary__text mb-2 pointer">
          <strong>{{ article.title }}</strong>
        </h3>
        <div class="font-weight-light grey--text title mb-2" v-html="article.featureContent" />
      </v-card-text>
      <v-divider />
      <v-card-text class="">
        <h3 v-if="article.createdByName" class="text-caption mb-2">
          <strong>Uploaded By: </strong>
          <v-chip small><div v-html="article.createdByName"></div></v-chip>
        </h3>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
export default {
  props: {
    article: {
      type: Object,
      default: {
        id: "",
        title: "",
        content: "",
        featureContent: "",
        featurePicture: "",
        createdByName: "",
      },
    },
  },
  data() {
    return {
      me: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}
</style>
