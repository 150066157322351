<template>
  <div>
    <section-banner :model="section"></section-banner>
    <h6 class="text-caption text-center mt-n12"></h6>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-container class="mt-12 mb-12">
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" v-for="(article, i) in articles.records" :key="i" class="d-flex" style="flex-direction:column">
          <article-card class=" flex-grow-1" :article="article"></article-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
import ArticleCard from "@/components/Frontend/Articles/ArticleCard";

export default {
  components: {
    SectionBanner,
    ArticleCard,
  },
  data() {
    return {
      showLoginState: false,
      articles: [],
      totalRecords: 0,
      isLoading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      section: {
        BannerSmall: true,
        Title: "Articles",
        Subtitle: "",
        HTMLDescription: "",
        HTMLCaption: "",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  created() {
    console.log(this.articles);
  },
  beforeDestroy() {},
  apollo: {
    articles: {
      query: gql`
        query Articles($limit: Int!, $skip: Int!, $query: JSON!) {
          articles(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              title
              content
              featureContent
              featurePicture
              headerPicture
              createdBy
              createdByName
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      variables() {
        const self = this;
        const limit = self.options.itemsPerPage;
        const skip = (self.options.page - 1) * limit;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
</style>
